/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { PlatformLocation, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, TransferState, inject, makeStateKey, signal } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class HelperService {
    @Inject(PLATFORM_ID) platformId: object = inject(PLATFORM_ID);
    location = inject(PlatformLocation);

    public isMobile = signal(false);
    public subDomain = signal('');
    transferState = inject(TransferState);
    breakPointObserver = inject(BreakpointObserver);
    private destroy = new Subject<void>();

    constructor() {
        this.breakPointObserver.observe('(max-width: 800px)').pipe(
            takeUntil(this.destroy)
        ).subscribe((state: BreakpointState) => {
            this.isMobile.set(state.matches);
        });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }

    getLocation() {
        return this.location;
    }

    setStorage(type: string, key: string, value: any) {
        if (isPlatformBrowser(this.platformId)) {
            if (type === 'local') localStorage.setItem(key, value);
            else if (type === 'session') sessionStorage.setItem(key, value);
        }
        else {
            const transferKey = makeStateKey<any>(key);
            this.transferState.set(transferKey, value);
        }
    }

    removeFromStorage(type: string, key: string) {
        if (isPlatformBrowser(this.platformId)) {
            if (type === 'local') localStorage.removeItem(key);
            else if (type === 'session') sessionStorage.removeItem(key);
        }
        else {
            const transferKey = makeStateKey<any>(key);
            this.transferState.remove(transferKey);
        }
    }

    getStorage(type: string, key: string): any {
        let value: any;

        if (isPlatformBrowser(this.platformId)) {
            if (type === 'local') value = localStorage.getItem(key);
            else if (type === 'session') value = sessionStorage.getItem(key);
        } else {
            const transferKey = makeStateKey<any>(key);
            value = this.transferState.get(transferKey, null);
        }
        if (value === null || value === undefined) {
            return null;
        }

        return value;
    }
};
