/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

function _window(): any {
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class WindowRefService {

  constructor(@Inject(DOCUMENT) private _doc: Document) { }

  get nativeWindow(): any {
    if (typeof window !== 'undefined') {
      return _window();
    }
    return null;
  }
}